<template>
  <div class="my-organization1">
    <div class="list">
      <div class="top">
        <div class="right">
          <div>
            检索词：
            <a-input v-model="search" placeholder="请输入图书或昵称或姓名" :maxLength='30'/>
            区间查询：
            <a-range-picker v-model="date" format="YYYY-MM-DD"/>
          </div>
          <div class="search-right-box">
            <span class="button-search" @click="onSearch">查询</span>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
      >
        <template slot="userLimit" slot-scope="text, record">
          {{ text == 1 ? '受限' : text == 0 ? '不受限' : ''}}
        </template>
        <template slot="userType" slot-scope="text, record">
          {{ text == 1 ? '机构会员' : text == 0 ? '机构访客' : ''}}
        </template>
        <template slot="consumeAll" slot-scope="text, record">
          {{ text }} 文
        </template>
        <template slot="countAllPage" slot-scope="text, record">
          {{ record.consumeType == 'ocrImage' ? text : 0}} 
        </template>
        <template slot="countAll" slot-scope="text, record">
          {{ record.consumeType != 'ocrImage' ? text : 0}} 
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getConsumeInstitution } from '@/api/institution'
import dayjs from 'dayjs';
import bus from '@/utils/bus'
import Cookies from 'js-cookie'

export default {
  components: { },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '流水号',
          dataIndex: 'id',
        },
        {
          title: '图书名称',
          dataIndex: 'bookName',
        },
        {
          title: '处理页数',
          dataIndex: 'countAll',
          scopedSlots: { customRender: 'countAllPage' },
        },
        {
          title: '处理字数',
          dataIndex: 'countAll',
          scopedSlots: { customRender: 'countAll' },
        },
        {
          title: '川资',
          dataIndex: 'consumeAll',
          scopedSlots: { customRender: 'consumeAll' },
        },
        {
          title: '说明',
          dataIndex: 'description',
        },
        {
          title: '用户姓名',
          dataIndex: 'nickName',
        },
        {
          title: '账户类型',
          dataIndex: 'userType',
          scopedSlots: { customRender: 'userType' },
        },
        {
          title: '使用限制',
          dataIndex: 'userLimit',
          scopedSlots: { customRender: 'userLimit' },
        },
        {
          title: '时间',
          dataIndex: 'sysCreateTime',
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      checkedNewsList: [], // 选中的列表
      date: [],
      search: '',
      userInfo: {}
    }
  },
  mounted() {
    this.userInfo = JSON.parse(Cookies.get('userInfo'))
    this.getList()
  },
  methods: {
    async getList() {
      const res = await getConsumeInstitution({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sysInstitutionId: this.userInfo.institutionId,
        search: this.search || undefined,
        startDate: this.date.length ? dayjs(this.date[0]).format("YYYY-MM-DD") : undefined,
        endDate: this.date.length ? dayjs(this.date[1]).format("YYYY-MM-DD") : undefined,
      })

      res.data.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res.data

      bus.$emit('totalNum', {
        amount: res.data.amount,
        consume: res.data.percent,
        surplus: res.data.surplus
      })
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.date = []
      this.search = ''
      this.getList()
    }
  }
};
</script>

<style lang="less">
.my-organization1 {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;

      .left {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .ant-radio-group {
          margin-left: 10px;
        }
      }

      .right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }

        .search-right-box {
          display: flex;
        }
      }
    }
  }

  .top {
    .ant-input {
      width: 240px;
      margin-right: 20px;
    }
  }
  
}
</style>
