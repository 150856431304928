<template>
<div>
  <a-modal
    class="add-apply-modal"
    :width="1000"
    title="添加机构用户"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div  class="button">
        <div @click="handleClose" class="cancle">取消</div>
        <div @click="handleOk" class="ok">确认</div>
      </div>
    </template>
   
    <div class="modal-content">
      <div class="search-top">
        <a-input v-model="searchKey" placeholder="请输入昵称或姓名或手机号" :maxLength='30' style="width: 300px;"/>
        <div class="search-right-box">
          <span class="button-search" @click="onSearch">查询</span>
          <span class="button-primary" @click="onReset">重置</span>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.userId"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="authStatus" slot-scope="text, record">
          <span v-for="item in authStatusList" v-if="item.value == text">{{ item.label }}</span>
        </template>
        <template slot="useLimit" slot-scope="text, record">
          {{ text == 1 ? '受限' : '不受限' }}
        </template>
      </a-table>
    </div>
  </a-modal>
  <ApplyAgreeModal
    v-if="applyAgreeVisible"
    :visible="applyAgreeVisible"
    :handleClose="handleApplyClose"
    :ids="selectedRowKeys"
    :handleOk="handleApplyOk"
  />
</div>
</template>

<script>
import { getApplyList } from '@/api/institution'
import ApplyAgreeModal from './ApplyAgreeModal.vue'
export default {
  components: {
    ApplyAgreeModal
  },
  props: [
    'visible',
    'handleClose',
    'handleCallBack'
  ],
  data() {
    return {
      date: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          width: 120
        },
        {
          title: '用户姓名',
          dataIndex: 'userName',
        },
        {
          title: '手机号',
          dataIndex: 'phoneNumber',
        },
        {
          title: '状态',
          dataIndex: 'authStatus',
          scopedSlots: { customRender: 'authStatus' },
        },
        {
          title: '使用限制',
          dataIndex: 'useLimit',
          scopedSlots: { customRender: 'useLimit' },
          width: 100
        },
        {
          title: '时间',
          dataIndex: 'sysCreateTime',
          ellipsis: true,
          width: 190
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      searchKey: '',
      selectedRowKeys: [],
      applyAgreeVisible: false,
      authStatusList: [
        {
          value: 0,
          label: '待审核'
        } ,
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 2,
          label: '审核不通过'
        },
      ],
    }
  },
  async mounted() {
    this.getList()
  },
  methods: {
    /** 提交 */
    async handleOk() {
      if (this.selectedRowKeys.length == 0) {
        return this.$message.warning('请先选择')
      }
      this.applyAgreeVisible = true
      
    },
    async getList() {
      const res = await getApplyList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.pageNum = 1
      this.searchKey = []
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleApplyClose() {
      this.applyAgreeVisible = false
    },
    handleApplyOk() {
      this.applyAgreeVisible = false
      this.handleCallBack()
    }
  }
}
</script>

<style lang="less">
.add-apply-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 120px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }
      }
    }

    .ant-modal-body {
      padding-top: 0;
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 400px;
      overflow-y: auto;

      .search-top {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
      }

      .search-right-box {
        display: flex;
      }
    }
  }
}

</style>