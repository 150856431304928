<template>
  <div class="organization-usage-log">
    <div class="list">
      <div class="top">
        <div class="right">
          <div style="display: flex; align-items: center;">
            <span class="button-search add-box" @click="onAdd">
              <a-icon type="plus-circle" />添加会员
            </span>
            检索词：
            <a-input v-model="searchKey" placeholder="请输入昵称或姓名或手机号" :maxLength='30'/>
             状态：
            <a-select
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="authStatus"
              placeholder="请选择"
              :options="authStatusList"
              @change="onSearch"
            >
            </a-select>
          </div>
          <div class="search-right-box">
            <span class="button-search" @click="onSearch">查询</span>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="personalData.rows"
        :row-key="record => record.id"
        :pagination="{
          total: personalData.total,
          current: pageNum,
          pageSize: pageSize,
          showTotal: () => `共${personalData.total}条`,
          showSizeChanger: true,
        }"
        @change="handlePageChange"
      >
        <template slot="authStatus" slot-scope="text, record">
          <span v-for="item in authStatusList" v-if="item.value == text">{{ item.label }}</span>
        </template>
        <template slot="useLimit" slot-scope="text, record">
          {{ text == 1 ? '受限' : '不受限' }}
        </template>
        <template slot="action" slot-scope="text, record">
          <div style="display: flex;justify-content: center;">
            <span class="see-text" v-if="record.authStatus == 0" @click="handleAuth(record, 'auth')">
              <a-icon type="check-square" />
              审核
            </span>
            <span class="see-text" @click="handleAuth(record, 'see')" v-else>
              <a-icon type="eye" />
                查看
            </span>
            <span
              v-if="record.useLimit"
              class="see-text"
              @click="handleLimit(record)"
            >
              <a-icon type="unlock" />设为不受限
            </span>
            <span class="see-text" @click="handleLimit(record)" v-else><a-icon type="lock" />设为受限</span>
          </div>
        </template>
      </a-table>
    </div>
    <AuthStatusModal
      v-if="authStatusVisible"
      :visible="authStatusVisible"
      :id="detailData.id"
      :handleClose="handleAuthStatusClose"
      :handleOk="handleAuthStatusOk"
      :type="authType"
    />

    <ApplyAddOrganization v-if="applyAddVisible" :visible="applyAddVisible" :handleClose="handleApplyClose" :handleCallBack="handleApplyOk"/>
  </div>
</template>

<script>
import { getManagerList, updateLimit } from '@/api/institution'
import AuthStatusModal from '@/components/AuthStatusModal'
import ApplyAddOrganization from './ApplyAddOrganization'

export default {
  components: { AuthStatusModal, ApplyAddOrganization },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: 60,
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true
        },
        {
          title: '用户姓名',
          dataIndex: 'userName',
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'userPhone',
          ellipsis: true
        },
        {
          title: '申请用途',
          dataIndex: 'applyPurpose',
          ellipsis: true
        },
        {
          title: '申请时间',
          dataIndex: 'sysCreateTime',
          width: 120,
          ellipsis: true
        },
        {
          title: '审核时间',
          dataIndex: 'authDate',
          width: 120,
          ellipsis: true
        },
        {
          title: '到期时间',
          dataIndex: 'expireDate',
          width: 120,
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'authStatus',
          scopedSlots: { customRender: 'authStatus' },
          width: 120
        },
        {
          title: '使用限制',
          dataIndex: 'useLimit',
          scopedSlots: { customRender: 'useLimit' },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 180
        },
      ],
      personalData:{
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      searchKey: '',
      authStatus: undefined,
      authStatusList: [
        {
          value: 0,
          label: '待审核'
        } ,
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 2,
          label: '审核不通过'
        },
      ],
      authStatusVisible: false,
      detailData: {},
      applyAddVisible: false, 
      authType: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await getManagerList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKey: this.searchKey || undefined,
        authStatus: this.authStatus
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.personalData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    // 重置
    onReset() {
      this.searchKey = ''
      this.authStatus = undefined
      this.pageNum = 1
      this.getList()
    },
    async handleLimit(data) {
      await updateLimit({
        id: data.id
      })

      this.$message.success('设置成功')

      this.getList()
    },
    handleAuth(data, type) {
      this.authStatusVisible = true
      this.detailData = data
      this.authType = type
    },
    handleAuthStatusClose() {
      this.authStatusVisible = false
    },
    handleAuthStatusOk() {
      this.authStatusVisible = false
      this.getList()
    },
    onAdd() {
      this.applyAddVisible = true
    },
    handleApplyClose() {
      this.applyAddVisible = false
    },
    handleApplyOk() {
      this.applyAddVisible = false
      this.getList()
    }
  }
};
</script>

<style lang="less">
.organization-usage-log {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
    }
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }
  }

  .list {
    width: 100%;
    // min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    margin-top: 10px;
    padding: 0px 26px 16px 26px;
    box-sizing: border-box;

    .top {
      width: 100%;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed #c6c4c4;

      .left {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .ant-radio-group {
          margin-left: 10px;
        }
      }

      .right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-calendar-picker {
          margin: 0 20px 0 0;
        }

        .search-right-box {
          display: flex;
        }
        .add-box {
          width: 140px;
          margin-left: 0;
          margin-right: 24px;
          i {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .top {
    .ant-select {
      width: 160px;
    }
    input {
      width: 200px;
      margin-right: 20px;
    }
  }
  

  .see-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
