<template>
  <div class="personal">
    <div class="nav-title">
      <i></i>首页— 机构管理
    </div>
    <div class="personal-content">
      <div>
        <div class="left-nav">
          <img :src="`${imgPath}${userInfo.avatarCenter}`" class="head"/>
          <div class="name" :title="userInfo.nikeName">Hi～{{userInfo.nikeName}}</div>
          <div class="nav-list">
            <div 
              v-for="(item, key) in navList"
              :key="key"
              @click="onChangeNav(key)"
              :class="[{active: navTypeChecked == key}]"
            >
              <img v-if="key == 'usagelog'" :src="require(`@/assets/icon-my-organization.png`)" />
              <img v-else :src="require(`@/assets/icon-usage-log.png`)" />
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div style="display: flex;align-items: center;">
            <span class="active">
              {{navList[navTypeChecked] }}
            </span>
            <i v-if="navTypeChecked == 'myOrganization'">本机构账户总计{{amount}}文，已用{{consume}}文，仍有{{surplus}}文</i>
          </div>
          
          <div style="display: flex;">
            <span class="button-primary" @click="onBack">返回</span>
          </div>
        </div>

        <UsageLog  v-if="navTypeChecked == 'usagelog'"/>

        <MyOrganization  v-if="navTypeChecked == 'myOrganization'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import MyOrganization from './MyOrganization.vue'
import UsageLog from './UsageLog.vue'
import bus from '@/utils/bus'

export default {
  components: { MyOrganization, UsageLog },
  data() {
    return {
      navList: {
        'usagelog': '用户审核',
        'myOrganization': '使用记录',
      },
      navTypeChecked: 'usagelog',
      userInfo: {},
      imgPath: process.env.VUE_APP_IMG_PATH,
      feedbackFlag: false,
      amount: '',
      consume: '',
      surplus: ''
    }
  },
  watch: {
    $route() {
      this.$router.go(0)
    },
  },
  async mounted() {
    this.navTypeChecked = 'usagelog'

    this.userInfo = JSON.parse(Cookies.get('userInfo'))

    bus.$on('totalNum', ({amount, consume, surplus}) => {
      this.amount = amount
      this.consume = consume
      this.surplus = surplus
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onChangeNav(type) {
      if (type == 'news') {
        return this.$router.push('/news')
      } else {
        this.feedbackFlag = false
        this.navTypeChecked = type
      }
    },
    onBack() {
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less">
.personal {
  min-height: 787px;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .title {
    display: flex;
    justify-content: space-between;
    .button-primary {
      height: 34px !important;
      margin-right: 0;
      color: #6868CE;
    }

    i {
      font-style: normal;
      font-size: 16px;
      font-weight: 500;
      color: #4A4A4A;
    }
  }
 
  .personal-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 51px;
  }

  .left-nav {
    flex: 198px 0 0;
    width: 198px;
    height: 407px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    margin-right: 16px;
    .head {
      width: 90px;
      height: 90px;
      margin: 22px 0 17px;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #6868CE;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
    }
    .nav-list {
      margin-top: 15px;
      div {
        margin: 0 19px;
        font-size: 14px;
        font-weight: 500;
        color: #4A4A4A;
        text-align: left;
        line-height: 28px;
        padding: 8px 0;
        border-bottom: 1px dashed #C6C4C4;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }

        &:last-child {
          border-bottom: none;
        }
        &.active {
          color: #6868CE;
        }
      }
    }
  }

  .content {
    flex: 1;
  }
}
</style>
