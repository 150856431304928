<template>
<div>
  <a-modal
    class="auth-institution-add"
    :width="800"
    :title="type == 'see' ? '查看用户信息' : '审核用户信息'"
    :visible="visible"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button" v-if="type == 'see'">
        <div @click="handleClose" class="ok">关闭</div>
      </div>
      <a-spin v-else :spinning="loading">
        <div class="button">
          <div @click="handleSubmit(2)">不通过</div>
          <div @click="handleSubmit(1)" class="ok">通过</div>
        </div>
      </a-spin>
    </template>
    <a-spin :spinning="loading">
      <div class="form-content">
        <div class="list">
          <span>用户昵称：{{ detailData.nickName }}</span><span>用户姓名：{{ detailData.userName }}</span>
          <span>手机号：{{ detailData.userPhone }}</span>
        </div>
        <div class="list">
          <span>邮箱：{{ detailData.email }}</span><span>证件号码：{{ detailData.userIdentity }}</span><span>职业：{{ detailData.userWork }}</span>
        </div>
        <div class="list">
          <span>使用限制：{{ detailData.useLimit == 1 ? '受限' : '不受限' }}</span>
          <span>状态：
            <template v-for="item in authStatusList" v-if="item.value == detailData.authStatus ">{{ item.label }}</template>
          </span>
        </div>
        <div class="list">
          申请用途：{{ detailData.applyPurpose }}
        </div>
        <div class="list" v-if="type == 'see'">
          <span>开通会员天数：{{ detailData.days }}</span>
          <span>到期日期：<a-date-picker v-model="detailData.expireDate" :disabled="true" style="width: 160px;"/></span>
        </div>
        <div class="time-box" v-else>
          到期时间：<a-date-picker format="YYYY-MM-DD" v-model="expireDate" :disabled-date="disabledDate"/>默认180天
        </div>
        <p class="textarea-box" v-if="type == 'see'">
          <span>审核意见：</span>
          {{  detailData.authRemark }}
        </p>
        <p class="textarea-box" v-else>
          <span>审核意见：</span>
          <a-textarea placeholder="请输入" allow-clear v-model="authRemark"/>
        </p>
      </div>
    </a-spin>
  </a-modal>
</div>
</template>

<script>
import { auditApply, getUserDetail } from '@/api/institution'
import dayjs from 'dayjs';
import Cookies from 'js-cookie'

export default {
  props: ['visible', 'handleClose', 'handleOk', 'id', 'type'],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'horizontal',
      authRemark: '',
      expireDate: '',
      detailData: {},
      authStatusList: [
        {
          value: 0,
          label: '待审核'
        } ,
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 2,
          label: '审核不通过'
        },
      ],
      loading: false
    }
  },
  async mounted() {
    this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}
    const res = await getUserDetail({id: this.id})
    res.data.expireDate = dayjs(res.data.expireDate).format("YYYY-MM-DD")
    this.detailData = res.data
  
    // 默认显示180天
    let today = new Date();
    let twoYearsLater = new Date();
    twoYearsLater.setDate(today.getDate() + 180);
    this.userInfo.institutionExpireDate = this.userInfo.institutionExpireDate.replace('年', '-').replace('月', '-').replace('日', '')
    let time = new Date(this.userInfo.institutionExpireDate)
    // 判断机构到期时间是否包含当天时间+180内
    if (twoYearsLater.getTime() > time) {
      twoYearsLater = time
    }
    this.expireDate = dayjs(twoYearsLater).format("YYYY-MM-DD")
  },
  methods: {
    disabledDate(current) {
      const today = new Date();
      return current && current.valueOf() < today.setHours(0, 0, 0, 0); // Disable dates before today
    },
    /** 确定 */
    async handleSubmit(status) {
      if (!this.expireDate || (!this.authRemark && status == 2)) {
        return this.$message.warning('请完善信息')
      }
      if (this.loading) return 
      this.loading = true
      await auditApply({
        id: this.id,
        authStatus: status,
        expireDate: this.expireDate,
        authRemark: this.authRemark,
      })

      this.loading = false
      this.$message.success('审核成功')
      this.handleOk()
    }
  },
}
</script>

<style lang="less">
.auth-institution-add {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #6868CE;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6868CE;
          cursor: pointer;
        }

        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
    .list {
      margin: 12px 0;
      display: flex;
      span {
        margin-right: 10px;
        flex: 33% 0 0;
      }
    }
    .time-box {
      padding: 12px 0;
      .ant-calendar-picker-input {
        width: 580px;
        margin-right: 30px;
      }
    }

    .textarea-box {
      display: flex;
      span {
        &:nth-of-type(1) {
          width: 78px;
        }
      }
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    top: 0;
  }
}
</style>