<template>
<div>
  <a-modal
    class="apply-agree-add"
    :width="600"
    title="授权确认"
    :visible="visible"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">取消</div>
        <div  @click="handleSubmit">确认</div>
      </div>
    </template>
    <div class="form-content">
      <div class="time-box">
        开通会员天数： <a-input :maxLength='30' v-model="days" style="width: 80px;margin-right: 6px;"/>天（如已开通，在原有到期时间上增加）
      </div>
      <p>审核意见：</p>
      <a-textarea placeholder="非必填，最多50字" allow-clear v-model="remark" :maxLength='50' />
    </div>
  </a-modal>
</div>
</template>

<script>
import { toInstitutionJoin } from '@/api/institution'
import Cookies from 'js-cookie'

export default {
  props: ['visible', 'handleClose', 'handleOk', 'ids'],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'horizontal',
      days: 180,
      remark: ''
    }
  },
  watch: {
    visible (val) {
      this.remark = ''
      this.days = 180
    }
  },
  methods: {
    /** 确定 */
    async handleSubmit() {
      if (!this.days) {
        return this.$message.warning('请完善信息')
      }
      const userInfo = JSON.parse(Cookies.get('userInfo'))

      await toInstitutionJoin({
        listId: this.ids,
        days: this.days,
        remark: this.remark,
        institutionId: userInfo.institutionId,
      })

      this.$message.success('授权成功')
      this.handleOk()
    }
  },
}
</script>

<style lang="less">
.apply-agree-add {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #6868CE;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6868CE;
          cursor: pointer;
        }

        :nth-child(2) {
          background: url(../../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
    .list {
      margin: 12px 0;
      display: flex;
      span {
        margin-right: 10px;
        flex: 1 0 0;
      }
    }
    .time-box {
      padding: 12px 0;
    }

    .ant-calendar-picker-input {
      width: 580px;
      margin-right: 30px;
    }
  }
}
</style>